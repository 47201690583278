import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import photo from "../assets/img/teste2.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(100 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  let toRotate = [ "Empoderar", "Praticar" ];

  
  const period = 1000;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(50);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }
  let element = <a href={"https://api.whatsapp.com/send?phone=5541995783261&text=Ol%C3%A1,%20Arthur!%0A%0AEu%20gostaria%20de%20ter%20*acesso%20a%20LawBits%20para%20praticar%20pe%C3%A7as%20jur%C3%ADdicas.*%20Vim%20pelo%20site"}>
  <button>Entre em Contato<ArrowRightCircle size={25} /></button> 
  </a>;;
  let url = 'www.google.com.br';
  const [isActive, setIsActive] = useState();
  // if (i18n.language === 'en') {
  //   url="https://www.canva.com/design/DAFk4xO46wU/5mlFFb8k7QTDmW4C0gKORg/view?utm_content=DAFk4xO46wU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink";
  // } else {
  //   url='https://www.canva.com/design/DAFk470bFA4/MdDry1m4BGdLZFdtQYtWag/view?utm_content=DAFk470bFA4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'
  // }


  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Bem vindo!</span>
                <h1>{`Lawbits\t`} <span className="txt-rotate" data-rotate=''><span className="wrap">{text}</span></span></h1>
                <p>
                Pratique questões jurídicas reais com tecnologia de última geração.<br></br>
                Preencha nosso formulário de contato e solicite acesso à nossa plataforma.
                </p>
                  {element}
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={photo} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
        <section id="scrollDown" className="scrollingDown">
              <a href="#what-we-do"><span></span></a>
      </section>
      </Container>
    </section>
  )
}
