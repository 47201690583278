// import 'animate.css';
// import { RiBriefcase3Line } from 'react-icons/ri';
// import { FaUniversity } from 'react-icons/fa';
// import { useState } from "react";
// import { Trans } from 'react-i18next';
// // import Grid from '@mui/material/Grid'; // Grid version 1
// import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
// import Item from '@mui/material/MenuItem';
// import { Card } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreateIcon from '@mui/icons-material/Create';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
// import { Step } from '@mui/material';
// import { Stepper } from '@mui/material';


// export const Qualification = () => {

//   const [activeTab, setActiveTab] = useState('work');

//   const showWorkTab = () => setActiveTab('work');

//   const showEducationTab = () => setActiveTab('education');

//   const Item = styled(Paper)(() => ({
//     backgroundColor: '#29347e',
//     padding: 8,
//     textAlign: 'center',
//     color: 'black',
//   }));

//   return (
//     <section className="qualification" id="what-we-do">
//     <h2 className="section__title">Como a Ada funciona</h2>
//     <span className="section__subtitle">A forma mais fácil de praticar e entender como funciona o mundo jurídico na prática!</span>
//     <div className='section_description'>
      
//     {/* <div className='cards'>
//     <h4><span>1. Escolha a especialidades</span></h4>
//     <p>Temos diversas opções de especialidade: civil, penal, trabalho ou administrativo.</p>
//     </div>
//     <div className='cards'>
//     <h4><span>2. Escreva sua petição</span></h4>
//     <p>Em pouco tempo, seu texto será enviado para nossa Inteligência Artificial, Ada, analisar.</p>
//     </div>
//     <div className='cards'>
//     <h4><span>3. Veja o feedback personalizado</span></h4>
//     <p>Nossa IA enviará um relatório completo com todas as informações que você pode precisar para melhorar.</p>
//     </div> */}

// {/* <Grid container spacing={30}>
//   <Grid xs display="flex" justifyContent="center" alignItems="center">
//   <Item><h4><span><AccountBalanceIcon></AccountBalanceIcon>1. Escolha a especialidades</span></h4>
//     <p>Temos diversas opções de especialidade: civil, penal, trabalho ou administrativo.</p></Item>
//   </Grid>
//   <Grid xs display="flex" justifyContent="center" alignItems="center">
//   <Item><h4><span><CreateIcon></CreateIcon>2. Escreva sua petição</span></h4>
//     <p>Em pouco tempo, seu texto será enviado para nossa Inteligência Artificial, Ada, analisar.</p></Item>

//   </Grid>
//   <Grid xs display="flex" justifyContent="center" alignItems="center">
//   <Item><h4><span><BookmarkAddedIcon></BookmarkAddedIcon>3. Veja o feedback personalizado</span></h4>
//     <p>Nossa IA enviará um relatório completo com todas as informações que você pode precisar para melhorar.</p></Item>
//   </Grid>
// </Grid> */}

// <Stepper activeStep={1} alternativeLabel>
//   {steps.map((label) => (
//     <Step key={label}>
//       <StepLabel>{label}</StepLabel>
//     </Step>
//   ))}
// </Stepper>
//     </div>
          
//   </section>
//   )
// }






// ---------------------------------------






// import * as React from 'react';
// import PropTypes from 'prop-types';
// import { styled } from '@mui/material/styles';
// import Stack from '@mui/material/Stack';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import Check from '@mui/icons-material/Check';
// import SettingsIcon from '@mui/icons-material/Settings';
// import GroupAddIcon from '@mui/icons-material/GroupAdd';
// import VideoLabelIcon from '@mui/icons-material/VideoLabel';
// import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// export const Qualification = () => {

// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// }));

// const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
//   color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
//   display: 'flex',
//   height: 22,
//   alignItems: 'center',
//   ...(ownerState.active && {
//     color: '#784af4',
//   }),
//   '& .QontoStepIcon-completedIcon': {
//     color: '#784af4',
//     zIndex: 1,
//     fontSize: 18,
//   },
//   '& .QontoStepIcon-circle': {
//     width: 8,
//     height: 8,
//     borderRadius: '50%',
//     backgroundColor: 'currentColor',
//   },
// }));

// function QontoStepIcon(props) {
//   const { active, completed, className } = props;

//   return (
//     <QontoStepIconRoot ownerState={{ active }} className={className}>
//       {completed ? (
//         <Check className="QontoStepIcon-completedIcon" />
//       ) : (
//         <div className="QontoStepIcon-circle" />
//       )}
//     </QontoStepIconRoot>
//   );
// }

// QontoStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
// };

// const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 22,
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       backgroundImage:
//         'linear-gradient( 95deg,#29347e 0%,#2A6F97 50%,#61A5C2 100%)',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       backgroundImage:
//         'linear-gradient( 95deg,#29347e 0%,#2A6F97 50%,#61A5C2 100%)',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     height: 3,
//     border: 0,
//     backgroundColor:
//       theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//     borderRadius: 1,
//   },
// }));

// const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#61A5C2',
//   zIndex: 1,
//   color: '#fff',
//   width: 50,
//   height: 50,
//   display: 'flex',
//   borderRadius: '50%',
//   justifyContent: 'center',
//   alignItems: 'center',
//   ...(ownerState.active && {
//     backgroundImage:
//       'linear-gradient( 136deg, #29347e 0%, #2A6F97 50%, #468FAF 100%)',
//     boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
//   }),
//   ...(ownerState.completed && {
//     backgroundImage:
//       'linear-gradient( 136deg, #29347e 0%, #2A6F97 50%, #468FAF 100%)',
//   }),
// }));

// function ColorlibStepIcon(props) {
//   const { active, completed, className } = props;

//   const icons = {
//     1: <AccountBalanceIcon/>,
//     2: <CreateIcon/>,
//     3: <BookmarkAddedIcon/>,
//   };

//   return (
//     <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
//       {icons[String(props.icon)]}
//     </ColorlibStepIconRoot>
//   );
// }

// ColorlibStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
//   /**
//    * The label displayed in the step icon.
//    */
//   icon: PropTypes.node,
// };
// const steps = ['Escolha a especialidade ', 'Escreva sua petição', 'Veja o feedback personalizado'];

// // export default function CustomizedSteppers() {
//   return (
//     <section className="qualification" id="what-we-do">
//     <h2 className="section__title">Como a Ada funciona</h2>
//     <span className="section__subtitle">A forma mais fácil de praticar e entender como funciona o mundo jurídico na prática!</span>
//     <Stack sx={{ width: '100%' }} spacing={4}>
//       {/* <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
//         {steps.map((label) => (
//           <Step key={label}>
//             <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper> */}
//       <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
//         {steps.map((label) => (
//           <Step key={label}>
//             <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Stack>
//     </section>
//   );
// }


import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import photo from "../assets/img/um-tiro-de-angulo-baixo-de-um-edificio-alto-de-negocios-em-nova-york.jpg";
import photo2 from "../assets/img/marcos-modernos-cidade.jpg";
// import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';

export const Qualification = () => {

  const steps = [' Escolha a especialidade', ' Escreva sua petição', ' Veja o feedback personalizado'];
  const steps_label = ['Temos diversas opções de especialidade: civil, penal, trabalho ou administrativo.', 'Em pouco tempo, seu texto será enviado para nossa Inteligência Artificial, Ada, analisar.', 'Nossa IA enviará um relatório completo com todas as informações que você precisa para melhorar!'];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const icons = {
    1: <AccountBalanceIcon/>,
    2: <CreateIcon/>,
    3: <BookmarkAddedIcon/>,
  };

  const handleNext = () => {
    let newSkipped = skipped;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  // mobile version:

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Escolha a especialidade',
    imgPath:
      photo,
  },
  {
    label: 'Escreva sua petição',
    imgPath:
      photo2,
  },
  {
    label: 'Veja o feedback personalizado',
    imgPath:
      photo,
  },
  {
    label: 'Essa é a experiência Lawbits!',
    imgPath:
      photo2,
  },
];
  const theme = useTheme();
  const maxSteps = images.length;

  const handleNextMobile = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackMobile = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <section className='qualification section' id='what-we-do'>
    <h2 className="section__title">O que fazemos</h2>
    <span className="section__subtitle">A forma mais fácil de praticar e entender como funciona o mundo jurídico na prática!</span>
    <div className='horizontal_stepper'>
    <Box sx={{ width: '100%', display: 'block', 'padding': '200px', }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label}{...stepProps} style={{'paddingLeft':'0px'}}>
              <StepLabel {...labelProps}>{icons[index + 1 ]}{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Essa é a experiência Lawbits!
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Resetar</Button>
          </Box>
        </React.Fragment>
      ) : (
        // Temos diversas opções de especialidade: civil, penal, trabalho ou administrativo.
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>{steps_label[activeStep]}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Voltar
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finalizado' : 'Próximo'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
    </div>

    {/* mobile version: */}





    <div className='mobile_stepper'>
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          // bgcolor: 'background.default',
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNextMobile}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBackMobile} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    </div>
    </section>
  );
}