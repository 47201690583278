import 'react-multi-carousel/lib/styles.css';
import { FaUniversity } from 'react-icons/fa';
import { MdComputer } from 'react-icons/md';
import { IoMdGlobe } from 'react-icons/io';
import { GiMagnifyingGlass } from 'react-icons/gi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Trans } from 'react-i18next';
import React, { useState } from 'react';

export const Skills = () => {

  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (cardId) => {
    if (activeCard === cardId) return setActiveCard(null
      );
    setActiveCard(cardId);
  }

  return (
  <section className='skills section' id='who-we-are'>
    <h2 className="section__title">Quem somos</h2>
    {/* <span className="section__subtitle">Minhas habilidades</span> */}
    <div className="page-wrapper">  
    <div 
        className={`card-wrapper ${activeCard === 'lawyer' ? 'active' : ''}`} 
        onClick={() => handleCardClick('lawyer')}
      > 
      <div className="card-details">
          <h3 className="card-title">Arthur A. de Menezes<div className="margin"></div><div className="arrow-icon"><ExpandMoreIcon/></div></h3>
        <p></p>
      </div>
      
      <div className="reveal-details">
        <p>CEO. Formado em Direito pela UFPR, e em ADS pela UP, possui experiência no universo jurídico e no da tecnologia.</p>
      </div>
    </div>


    <div 
        className={`card-wrapper ${activeCard === 'developer' ? 'active' : ''}`} 
        onClick={() => handleCardClick('developer')}
      >
      <div className="card-details">
        <h3 className="card-title">Ada<div className="margin"></div><div className="arrow-icon"><ExpandMoreIcon/></div></h3>
        <p></p>
      </div>
      
      <div className="reveal-details">
        <p>Nossa IA, Ada, é capaz de analisar as questões jurídicas e auxiliar o estudante no seu estudo jurídico prático. O nome foi inspirado na Ada Lovelace, a primeira programadora</p>
      </div>
    </div>
    </div>
    <div className="page-wrapper">
    <div 
        className={`card-wrapper ${activeCard === 'international' ? 'active' : ''}`} 
        onClick={() => handleCardClick('international')}
      >
      <div className="card-details">
        <h3 className="card-title">Emilia Castello<div className="margin"></div><div className="arrow-icon"><ExpandMoreIcon/></div></h3>
        <p></p>
      </div>
      
      <div className="reveal-details">
        <p>CTO. Desenvolvedora Full Stack e quase formanda em Ciência da Computação pela UFPR, tem experiência no desenvolvimento de software. 
        </p>
      </div>
    </div>

    <div 
        className={`card-wrapper ${activeCard === 'researcher' ? 'active' : ''}`} 
        onClick={() => handleCardClick('researcher')}
      >
      <div className="card-details">
        <h3 className="card-title">A Equipe<div className="margin"></div><div className="arrow-icon"><ExpandMoreIcon/></div></h3>
        <p></p>
      </div>
      
      <div className="reveal-details">
        <p>A nossa equipe multidisciplinar é formada por profissionais que representam o melhor da intersecção entre direito e tecnologia.</p>
      </div>
    </div>
    
    </div>
  </section>
  )
}
